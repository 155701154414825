import { useEffect, useState } from 'react';

import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BalanceLogModal from './BalanceLogModal';
import BetItemDetailModal from './BetItemDetailModal';
import ChangePasswordModal from './ChangePasswordModal';
import ConfirmBetModal from './ConfirmBetModal';
import ConfirmationModal from './ConfirmationModal';
import CustomiseChanglongModal from './CustomiseChanglongModal';
import DatePickerModal from './DatePickerModal';
import ErrorModal from './ErrorModal';
import GameNoticeModal from './GameNoticeModal';
import GeneralModal from './GeneralModal';
import GuaMaModal from './GuaMaModal';
import LatestAnnouncementModal from './LatestAnnouncementModal';
import LoadingModal from './LoadingModal';
import LoginAndRehisterModal from './LoginAndRehisterModal';
import MobileBankCardDetailModal from './MobileBankCardDetailModal';
import MobileBetsListModal from './MobileBetsListModal';
import MobileChangLongModal from './MobileChangLongModal';
import MobileNotificationModal from './MobileNotificationModal';
import MobileOnlineRadioListModal from './MobileOnlineRadioListModal';
import MobileOnlineStatusModal from './MobileOnlineStatusModal';
import MobileReminderModal from './MobileReminderModal';
import MobileThemeColorModal from './MobileThemeColorModal';
import MobileTransactionFilterModal from './MobileTransactionFilterModal';
import NotificationModal from './NotificationModal';
import PresetAmountModal from './PresetAmountModal';
import RadioButtonListModal from './RadioButtonListModal';
import SuccessModal from './SuccessModal';
import TextInputModal from './TextInputModal';
import ThirdPartyModal from './ThirdPartyModal';
import TopUpPanelModal from './TopUpPanelModal';
import TransactionRecordDetailModal from './TransactionRecordDetailModal';


import { appActions } from '../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  modalAlert: state.appReducers.modalAlert,
  platform: state.appReducers.platform,
  themeColor: state.appReducers.themeColor,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function ModalAlert({ modalAlert, platform, updateModalAlert, themeColor }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = history.location || {};
  const [_modalAlert, setModalAlert] = useState(modalAlert || {
    visible: false
  });
  const toggle = () => {
    updateModalAlert({
      visible: !modalAlert.visible
    });
  };

  const resetModal = () => {
    updateModalAlert({
      visible: false
    });
  };


  useEffect(() => {
    let timeoutId = null;
    if (modalAlert.visible === false) {
      timeoutId = setTimeout(() => setModalAlert({
        visible: false
      }), 400);
    } else if (modalAlert.type === 'gameNotice') {
      if (pathname === '/user/games') {
        setModalAlert(modalAlert);
      }
    } else {
      setModalAlert(modalAlert);
    }
    return () => clearTimeout(timeoutId);
  }, [modalAlert]);

  useEffect(() => {
    window.onbeforeunload = resetModal;
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    history.listen(() => {
      resetModal();
    });
  }, [history]);


  const renderModalAlert = () => {
    switch (_modalAlert.type) {
      case 'error':
        return (<ErrorModal data={_modalAlert.data} />);

      case 'success':
        return (<SuccessModal data={_modalAlert.data} />);

      case 'betItemDetail':
        return (<BetItemDetailModal data={_modalAlert.data} />);

      case 'confirmation':
        return (<ConfirmationModal data={_modalAlert.data} />);

      case 'guama':
        return (<GuaMaModal data={_modalAlert.data} />);

      case 'gameNotice':
        return (<GameNoticeModal data={_modalAlert.data} />);

      case 'info':
        return (<GeneralModal platform={platform} header={_modalAlert.type === 'info' ? t('MODAL_INFO') : _modalAlert.type === 'error' ? t('MODAL_ERROR') : modalAlert.header} message={_modalAlert.message} toggle={toggle} />);

      case 'loading':
        return (<LoadingModal platform={platform} />);

      case 'datepicker':
        return (<DatePickerModal dates={modalAlert.dates} />);

      case 'radioButtonList':
        return (<RadioButtonListModal data={_modalAlert.data} onChange={_modalAlert.onChange} value={_modalAlert.value} />);

      case 'presetAmount':
        return (<PresetAmountModal />);

      case 'confirmBet':
        return (<ConfirmBetModal data={_modalAlert.data} />);

      case 'balanceInfo':
        return (<BalanceLogModal />);

      case 'topUp':
        return (<TopUpPanelModal depositResult={_modalAlert.data} />);

      case 'transactionDetail':
        return (<TransactionRecordDetailModal data={_modalAlert.data} onCancelClick={_modalAlert.onCancelClick} />);

      case 'notification':
        return (<NotificationModal data={_modalAlert.data} />);

      case 'customizeChangLong':
        return (<CustomiseChanglongModal data={_modalAlert.data} />);

      case 'latestAnnouncement':
        return (<LatestAnnouncementModal data={_modalAlert.data} />);

      case 'changePassword':
        return (<ChangePasswordModal data={_modalAlert.data} />);

      case 'thirdParty':
        return (<ThirdPartyModal data={_modalAlert.data} />);

      case 'textInput':
        return (<TextInputModal data={_modalAlert.data} />);

      case 'mobileBankCardDetail':
        return (<MobileBankCardDetailModal data={_modalAlert.data} />);

      case 'mobileReminder':
        return (<MobileReminderModal data={_modalAlert.data} />);

      case 'mobileThemeColor':
        return (<MobileThemeColorModal />);

      case 'mobileOnlineStatus':
        return (<MobileOnlineStatusModal selectedData={_modalAlert.selectedData} />);

      case 'mobileOnlineRadioList':
        return (<MobileOnlineRadioListModal data={_modalAlert.data} onChange={_modalAlert.onChange} value={_modalAlert.value} />);

      case 'mobileTransactionFilter':
        return (<MobileTransactionFilterModal data={_modalAlert.data} />);

      case 'mobileBetsList':
        return (<MobileBetsListModal data={_modalAlert.data} />);

      case 'mobileNotification':
        return (<MobileNotificationModal data={_modalAlert.data} />);

      case 'loginAndRegister':
        return (<LoginAndRehisterModal data={_modalAlert.data} />);

      case 'mobileChangLong':
        return (<MobileChangLongModal data={_modalAlert.data} />);

      default:
        return null;
    }
  };

  return (
    <Modal
      size="sm"
      isOpen={_modalAlert.visible}
      centered
      className={`theme-${themeColor || 'blue'} ${platform && platform.toLowerCase()} ${modalAlert.type} ${modalAlert.visible ? 'popUpOpen' : 'popUpClose'}`}
    >
      { renderModalAlert() }
    </Modal>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlert);