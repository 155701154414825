import moment from 'moment';
import 'moment/locale/zh-cn';

const validateNumber = (text, escapeCharacter = [], skipTrim = true, returnType = 'number') => {
  if (!text) return undefined;
  let tempText = skipTrim ? text : text.trim();
  if ((Number.isNaN(Number(text)) || text.includes('.')) && !escapeCharacter.includes(text)) {
    tempText = tempText.split('').map(x => ((Number.isNaN(Number(x)) || x === '.') && !escapeCharacter.includes(x) ? '' : x)).join('');
  }
  return !Number.isNaN(Number(text)) && returnType === 'number' ? parseInt(tempText, 10) : tempText;
};

const renderHeXiaoBalls = (preZodiac = '猪', currentZodiac, zodiacTotalBall = 48) => {
  const REDBALL = ['1', '2', '7', '8', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
  const BLUEBALL = ['3', '4', '9', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
  const GREENBALL = ['5', '6', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
  const zodiacList = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];
  const result = [];
  const startIndex = zodiacList.indexOf(preZodiac) - zodiacList.indexOf(currentZodiac) + 1;
  for (let i = startIndex; i <= zodiacTotalBall; i += 12) {
    let ballStyle = '';
    if (i > 0) {
      if (REDBALL.includes(`${i}`)) {
        ballStyle = 'circle circleRed';
      } else if (BLUEBALL.includes(`${i}`)) {
        ballStyle = 'circle circleBlue';
      } else if (GREENBALL.includes(`${i}`)) {
        ballStyle = 'circle circleGreen';
      }
      result.push({
        value: i, className: `m-auto ${ballStyle}`
      });
    }
  }
  return result;
};

const renderSeBoBalls = value => {
  const REDBALL = ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
  const BLUEBALL = ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
  const GREENBALL = ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
  if (value === '红波') {
    return REDBALL.map(item => ({
      value: item, className: 'circle circleRed'
    }));
  }
  if (value === '蓝波') {
    return BLUEBALL.map(item => ({
      value: item, className: 'circle circleBlue'
    }));
  }
  if (value === '绿波') {
    return GREENBALL.map(item => ({
      value: item, className: 'circle circleGreen'
    }));
  }
};
const arrWxNo = [{
  begindate: '2021-02-12',
  enddate: '2022-01-31',
  金: '07,08,21,22,29,30,37,38',
  木: '03,04,11,12,19,20,33,34,41,42,49',
  水: '09,10,17,18,25,26,39,40,47,48',
  火: '05,06,13,14,27,28,35,36,43,44',
  土: '01,02,15,16,23,24,31,32,45,46'
},
{
  begindate: '2022-02-01',
  enddate: '2023-01-21',
  金: '01,08,09,22,23,30,31,38,39',
  木: '04,05,12,13,20,21,34,35,42,43',
  水: '10,11,18,19,26,27,40,41,48,49',
  火: '06,07,14,15,28,29,36,37,44,45',
  土: '02,03,16,17,24,25,32,33,46,47'
},
{
  begindate: '2023-01-22',
  enddate: '2025-01-28',
  金: '02,03,10,11,24,25,32,33,40,41',
  木: '06,07,14,15,22,23,36,37,44,45',
  水: '12,13,20,21,28,29,42,43',
  火: '01,08,09,16,17,30,31,38,39,46,47',
  土: '04,05,18,19,26,27,34,35,48,49'
}];
const REDBALL = ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
const BLUEBALL = ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
const GREENBALL = ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
const renderWuXingBalls = value => {
  const result = [];
  const currentWX = arrWxNo.find(x => new Date() >= new Date(x.begindate) && new Date() <= new Date(x.enddate));
  if (currentWX) {
    currentWX[`${value}`].split(',').forEach(item => {
      if (REDBALL.includes(item)) {
        result.push({
          value: item, className: 'circle circleRed'
        });
      }
      if (BLUEBALL.includes(item)) {
        result.push({
          value: item, className: 'circle circleBlue'
        });
      }
      if (GREENBALL.includes(item)) {
        result.push({
          value: item, className: 'circle circleGreen'
        });
      }
    });
  }
  return result;
};
const renderWSBalls = value => {
  const result = [];
  REDBALL.forEach(item => {
    if (item.slice(-1) === value.slice(0, 1)) {
      result.push({
        value: item, className: 'circle circleRed'
      });
    }
  });
  BLUEBALL.forEach(item => {
    if (item.slice(-1) === value.slice(0, 1)) {
      result.push({
        value: item, className: 'circle circleBlue'
      });
    }
  });
  GREENBALL.forEach(item => {
    if (item.slice(-1) === value.slice(0, 1)) {
      result.push({
        value: item, className: 'circle circleGreen'
      });
    }
  });
  return result.sort((a, b) => a.value - b.value);
};

const renderResultDateCell = (data, language) => {
  moment.locale(language === 'zh' ? 'zh-cn' : 'en');
  const date = moment(data.lotteryTime).format('YYYY-MM-DD');
  const time = `(${moment.weekdays(moment(data.lotteryTime).days())}) ${moment(data.lotteryTime).format('HH:mm')}`;
  return [{
    value: date,
    className: 'd-flex align-items-center justify-content-center'
  }, {
    value: time,
    className: 'd-flex align-items-center justify-content-center'
  }];
};

const inviteCodeValidation = args => {
  const regex = /^[a-zA-Z0-9]+$/;
  return regex.test(args);
};

const quickBetDictionary = {
  红波: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46],
  蓝波: [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48],
  绿波: [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49],
  红大: [29, 30, 34, 35, 40, 45, 46],
  蓝大: [25, 26, 31, 36, 37, 41, 42, 47, 48],
  绿大: [27, 28, 32, 33, 38, 39, 43, 44, 49],
  红小: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24],
  蓝小: [3, 4, 9, 10, 14, 15, 20],
  绿小: [5, 6, 11, 16, 17, 21, 22],
  红单: [1, 7, 13, 19, 23, 29, 35, 45],
  蓝单: [3, 9, 15, 25, 31, 37, 41, 47],
  绿单: [5, 11, 17, 21, 27, 33, 39, 43, 49],
  红双: [2, 8, 12, 18, 24, 30, 34, 40, 46],
  蓝双: [4, 10, 14, 20, 26, 36, 42, 48],
  绿双: [6, 16, 22, 28, 32, 38, 44],
  大号: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
  小号: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  单号: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49],
  双号: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48],
  大单: [25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49],
  大双: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48],
  小单: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23],
  小双: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
  合单: [1, 3, 5, 7, 9, 10, 12, 14, 16, 18, 21, 23, 25, 27, 29, 30, 32, 34, 36, 38, 41, 43, 45, 47, 49],
  合双: [2, 4, 6, 8, 11, 13, 15, 17, 19, 20, 22, 24, 26, 28, 31, 33, 35, 37, 39, 40, 42, 44, 46, 48],
};

const CONST_ZODIACS = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];

const CONST_NUMS = [
  '01,13,25,37,49', '02,14,26,38', '03,15,27,39',
  '04,16,28,40', '05,17,29,41', '06,18,30,42',
  '07,19,31,43', '08,20,32,44', '09,21,33,45',
  '10,22,34,46', '11,23,35,47', '12,24,36,48'
];

function sort(z) {
  const befor = [];
  const after = [];
  let point = 0;// 当前生肖实际位置
  for (let i = 0; i < CONST_ZODIACS.length; i++) {
    if (z === CONST_ZODIACS[i]) {
      point = i;
      befor.push(z);
      break;
    }
  }
  for (let j = CONST_ZODIACS.length - 1; j >= 0; j--) {
    if (j > point) { // 后面
      after.push(CONST_ZODIACS[j]);
    }
    if (j < point) { // 前面
      befor.push(CONST_ZODIACS[j]);
    }
  }
  let result = [];
  result = result.concat(befor);
  result = result.concat(after);
  return result;
}


function getNumByAnimal(animal, targanimal) {
  const newzodiacs = sort(animal);
  let point = 0;// 排序后的位置
  let s = 0;
  for (s = 0; s < newzodiacs.length; s++) {
    const sz = newzodiacs[s];
    if (sz === targanimal) {
      break;
    }
    point += 1;
  }
  if (s <= newzodiacs.length) return CONST_NUMS[point];
  return '';
}


export {
  getNumByAnimal, validateNumber, renderHeXiaoBalls, renderResultDateCell, renderSeBoBalls, renderWuXingBalls, renderWSBalls, inviteCodeValidation, quickBetDictionary
};